import React from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderBrand,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormTextarea,CFormSelect,CInputGroup,CInputGroupText,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CBadge,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownHeader,CDropdownItem,CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyDialogview,
	MyDialogviewfullscreen,
	MyDialogpasien,
	MyDialoglookup,
	MyHeaderProfile,
	MyPagination,
	MySessionextender,
} from '../../components/index'
import ImageGallery from 'react-image-gallery';
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Rekammedis = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { tabel_id } 				= useParams(); 

	//--HOOKS--/
	const uNavigate 				= useNavigate();
	const uDispatch 				= useDispatch();
	//--END HOOKS--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 7) : (uSettingObj.jml_laporan || 15);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const [uHtml500msg,setHtml500msg]		= React.useState(); 
	const [uTimeelapse,setTimeelapse]		= React.useState(0); 
	const [uJmlHal,setJmlHal] 				= React.useState(1);

	const uPasienID 						= parseInt(tabel_id || "0")
	const [uDatapasienObj,setDatapasienObj]	= React.useState({})
	const [uDatatabelArr,setDatatabelArr]	= React.useState([])
	const [uJmlData,setJmlData]				= React.useState(0)
	const [uRowselect,setRowselect]			= React.useState(-1);
	const [uIDselect,setIDselect]			= React.useState(0);
	const [uPageActive,setPageActive]		= React.useState(1);

	const uDatacabangArr					= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uInpcabangid,setInpcabangid]		= React.useState(0);//parseInt(uTokenObj.user_cabangid||"0"));
	const [uIsLoadprocessing,setLoadprocessing]= React.useState(false)
	const [uIsActionprocess,setActionprocess]= React.useState(false)
	const [uIsTabelfistload,setTabelfistload]= React.useState(true)

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false)
	const [uDlgviewObj,setDlgviewObj]		= React.useState({})
	//--END DLG_VIEW--/

	//--DLG_VIEWFS--/
	const [uISDlgviewFSshow,setDlgviewFSshow]= React.useState(false)
	const [uDlgviewFSObj,setDlgviewFSObj]	= React.useState({})
	const [uDatadokterArr,setDatadokterArr]	= React.useState([])
	const [uDataanamnaseArr,setDataanamanseArr]	= React.useState([])
	const [uDatadiagnosaArr,setDatadiagnosaArr]	= React.useState([])
	//--END DLG_VIEWF--/

	//---DLG_PASIEN--/
	const [uIsDlgpasienshow,setDlgpasienshow]= React.useState(false);
	const [uDlgPasienID,setDlgPasienID]		= React.useState(0);
	//---END DLG_PASIEN--/

	//---DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//---END DLG_LOOKUP--/

	const uPeriksainitObj 		= uDatapasienObj.periksa_init;
	//--> {cabang_id,userinit,ip_init}
	let uIsHapusallow			= (uTokenObj.userhak==="FREEROLE");
	if(uTokenObj.userhak==="ADMIN") {
		uIsHapusallow = uTokenObj.user_cabangarr.includes((uInpcabangid||"0").toString());
	}
	//uIsHapusallow			= false;//-->TESTING

	let uIsPeriksaallow		= (uTokenObj.userhak==="FREEROLE");
	let uIsShowperiksa		= false;
	let uIsShowbatal		= (UFunc.isEmpty(uPeriksainitObj)!==true);
	//if(JSON.stringify(uPeriksainitObj)==="{}") uIsShowbatal = false;

	/*//--INIT_PERIKSA--/
	1. Jika InitPERIKSA Empty ==> SHOW PERIKSA
	2. Jika USERINIT == TOKENOBJ.userinit ==> SHOW BATAL - SHOW PERIKSA
	//--END INIT_PERIKSA--*/
	if(uTokenObj.userhak==="FREEROLE") {
		/*if(UFunc.isEmpty(uPeriksainitObj)===false)
			uIsShowperiksa = (uTokenObj.userinit||"").trim() === (uPeriksainitObj.userinit||"").trim();
		else//-*/ 
			uIsShowperiksa = true;
	} else if(uTokenObj.userhak==="ADMIN") {
		if(UFunc.isEmpty(uPeriksainitObj)===true) {
			uIsPeriksaallow = true;
			uIsShowperiksa 	= true;
		} else {
			uIsShowbatal 	= uTokenObj.user_cabangarr.includes( (uPeriksainitObj.cabang_id||"").toString() );
			uIsPeriksaallow = (uTokenObj.user_cabangarr||[]).includes( (uPeriksainitObj.cabang_id||"").toString() );
			//uIsShowperiksa 	= (uTokenObj.userinit||"").trim() === (uPeriksainitObj.userinit||"").trim();
			uIsShowperiksa 	= true;// (uTokenObj.userinit||"").trim() === (uPeriksainitObj.userinit||"").trim();
		}
	} else if(uTokenObj.userhak==="DOKTER") {
		if((uDatapasienObj.antrian_status||"TIDAK")==="YA") {
			uIsPeriksaallow = true;
		} 

		if(UFunc.isEmpty(uPeriksainitObj)===true) {
			uIsShowperiksa 	= 
			((uDatapasienObj.antrian_dokterid||"0").toString() === (uTokenObj.user_dokterid).toString()
				|| ((uDatapasienObj.antrian_dokterid||"0").toString() === "0")
				);
		} else {
			uIsShowperiksa 	= true;//(uTokenObj.userinit||"").trim() === (uPeriksainitObj.userinit||"").trim();
			uIsShowbatal 	= (uTokenObj.userinit||"").trim() === (uPeriksainitObj.userinit||"").trim();
		}
	}
	const uISExtenderactive = ((uTokenObj.userinit||"").trim() === ((uPeriksainitObj||{}).userinit||"").trim());
	/*//uIsShowbatal = false;
	//uIsShowperiksa = false; uIsShowbatal = false; uIsPeriksaallow = false;
	console.log("(Rekammedis ) uPeriksainitObj => "+JSON.stringify(uPeriksainitObj));
	console.log("(Rekammedis ) uPeriksainitObj.userinit => "+uPeriksainitObj.userinit)
	
	console.log("(Rekammedis ) uIsShowperiksa => "+uIsShowperiksa)//-*/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikBack = () => {
		if(uUrlbefore === "") { uNavigate(-1); return }
		uNavigate(uUrlbefore,{replace:true})
	}
	const hdlKlikReset = async() => { 
		const vFuncnext = () => {
			//setTabelfistload(false)
			//setRowselect(-1);
			//setDatatabelArr([])
			setTabelfistload(true)
			setInpcabangid(0);
			initResettabel();
			apiLoadawal(); 
		}

		if(uHtml500msg) vFuncnext();
		else {
			if(await(Konfirm("Apakah Anda Yakin ?"+
				""))) {
				vFuncnext(); return;//-->AGAR_INIT_PERIKSA_TERRESET
				initResettabel(); apiLoadtabel();
			}
		}
	}
	const hdlKlikAntrian = () => {
		uNavigate("/subpasien/pasien/daftarantri/"+uPasienID);
	}
	const hdlKlikPasien = () => {
		setDlgPasienID(uPasienID);
		setDlgpasienshow(true);
	}
	const hdlToggleDlgpasien = () => {setDlgpasienshow(false)}

	const hdlKlikDetilRM = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		const vCaptionupper = (UFunc.TglAngka(uDatatabelArr[vArridx].tgl_periksa)+" :: "+
			uDatatabelArr[vArridx].dokter_caption).toUpperCase();

		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowidx);

		setIDselect(_IDTABEL)
		uDlgviewObj.dlg_centered = true;
		uDlgviewObj.header 	= "Detil RM: "+vCaptionupper;
		uDlgviewObj.jenis 	= "DETIL";

		if((uDatatabelArr[vArridx].init_detil||false)===true) {
			setDlgviewshow(true);
			return;
		}

		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikEditRM = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		const vCaptionupper = (UFunc.TglAngka(uDatatabelArr[vArridx].tgl_periksa)+" :: "+
			uDatatabelArr[vArridx].dokter_caption).toUpperCase();

		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowidx);

		const vItemJml	= parseInt(uDatatabelArr[vArridx].items_count || 1);
		const vItemArr	= uDatatabelArr[vArridx].items_arr || [];

		uDlgviewFSObj.header 		= "Edit RM: "+vCaptionupper;
		uDlgviewFSObj.rmid			= _IDTABEL;
		uDlgviewFSObj.tgl_periksa	= uDatatabelArr[vArridx].tgl_periksa;
		uDlgviewFSObj.dokter_caption= uDatatabelArr[vArridx].dokter_caption;
		uDlgviewFSObj.anamnesa		= uDatatabelArr[vArridx].anamnesa;
		uDlgviewFSObj.satusehat_status		= uDatatabelArr[vArridx].satusehat_status;
		if(vItemArr.length === vItemJml) {
			uDlgviewFSObj.items_arr 	= vItemArr;
			setDlgviewFSshow(true);
			return;
		}

		apiLoaditemedit(_IDTABEL);
	}
	const hdlKlikFotopasien = () => {

		uNavigate("/subpasien/pasien/fotopasien/"+uPasienID);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionupper = (UFunc.TglAngka(uDatatabelArr[vArridx].tgl_periksa)+" :: "+
			uDatatabelArr[vArridx].dokter_caption).toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <b>"+vCaptionupper+"</b>"+
			"<br/><i>Seluruh Data yang Terkait dengan Rekammedis ini Akan DIBATALKAN, "+
			"termasuk Data Pembayaran dan Fee Dokternya.</i>"+
			"<br/><br/>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikToggleterapi = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		const vCaptionupper = UFunc.HariAngka( uDatatabelArr[vArridx].tgl_periksa ).toUpperCase();

		const vItemJml	= parseInt(uDatatabelArr[vArridx].items_count || 1);
		const vItemArr	= uDatatabelArr[vArridx].items_arr || [];

		if(vItemArr.length === vItemJml) {
			setRowselect(-1);
			uDatatabelArr[vArridx].init_expand = (uDatatabelArr[vArridx].init_expand||"TIDAK")==="TIDAK"
				? "YA" :"TIDAK";
			setEfekview(!uEfekview);
			return;
		}

		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowidx);
		apiLoaditem(_IDTABEL);
	}
	const hdlKlikDokteredit = (_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		const vCaptionupper = UFunc.HariAngka( uDatatabelArr[vArridx].tgl_periksa ).toUpperCase();

		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowidx);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return

		apiProseseditRM(_IDITEM)
	}
	const hdlKlikBatalperiksa = async() => {
		if(await(Konfirm("<b>BATALKAN Billing Pemeriksaan..</b>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiProsesbatal();
	}
	const hdlKlikBillingperiksa = () => { apiCheckingperiksa() }

	const hdlKlikEditRMtoggleon = (_IDITEM,_JENISTOGGLE) => {
		_IDITEM 		= parseInt(_IDITEM)||0; if(_IDITEM < 0) return;
		_JENISTOGGLE	= (_JENISTOGGLE||"").toUpperCase();

		const vArridx 	= UFunc.getObjectIndeks((uDlgviewFSObj.items_arr||[]),_IDITEM,"id_item");

		let vValueedit;
		if(_JENISTOGGLE === "DIAGNOSAKET") {
			vValueedit = ((uDlgviewFSObj.items_arr||[])[vArridx] || {}).diagnosa_ket ||"";
		} else if(_JENISTOGGLE === "KETGIGI") {
			vValueedit = ((uDlgviewFSObj.items_arr||[])[vArridx] || {}).ket_gigi ||"";
		} else if(_JENISTOGGLE === "TINDAKAN") {
			vValueedit = ((uDlgviewFSObj.items_arr||[])[vArridx] || {}).tindakan ||"";
		} else if(_JENISTOGGLE === "DOKTER") {
			uDlgviewFSObj.togglejenis	= undefined;
			uDlgviewFSObj.toggleid		= undefined;
			uDlgviewFSObj.togglevalue	= undefined;

			//--LOOKUP--/
			const vDatalookupArr = uDatadokterArr.map(vItems =>{ 
				return {id: vItems.id,caption:vItems.dokter_caption} 
			})

			uDlglookupObj.lookup_arr 	= vDatalookupArr;
			uDlglookupObj.header 		= ((uBahasaObj.word_lookup||"Lookup")+": "+
				(uBahasaObj.caption_datadokter||"Data Dokter"))

			uDlglookupObj.inputvalue 	= "";
			uDlglookupObj.jenislookup 	= _JENISTOGGLE;
			setDlglookupshow(true)
			//--END LOOKUP--/
			return;
		} else if(_JENISTOGGLE === "ANAMNESA") {
			uDlgviewFSObj.togglejenis	= undefined;
			uDlgviewFSObj.toggleid		= undefined;
			uDlgviewFSObj.togglevalue	= undefined;

			//--LOOKUP--/
			const vDatalookupArr = uDataanamnaseArr.map(vItems =>{ 
				return {id: vItems.id,caption:vItems.nama_anamnase} 
			})

			uDlglookupObj.lookup_arr 	= vDatalookupArr;
			uDlglookupObj.header 		= ((uBahasaObj.word_lookup||"Lookup")+": "+
				(uBahasaObj.caption_dataanamnase||"Data Anamnesa"))

			uDlglookupObj.inputvalue 	= "";
			uDlglookupObj.jenislookup 	= _JENISTOGGLE;
			setDlglookupshow(true)
			//--END LOOKUP--/
			return;
		} else if(_JENISTOGGLE === "DIAGNOSA") {
			uDlgviewFSObj.togglejenis	= _JENISTOGGLE;
			uDlgviewFSObj.toggleid		= _IDITEM;
			uDlgviewFSObj.togglevalue	= undefined;

			//--LOOKUP--/
			const vDatalookupArr = uDatadiagnosaArr.map(vItems =>{ 
				return {id: vItems.id,caption:vItems.nama_diagnosa} 
			})

			uDlglookupObj.lookup_arr 	= vDatalookupArr;
			uDlglookupObj.header 		= ((uBahasaObj.word_lookup||"Lookup")+": "+
				(uBahasaObj.caption_datadiagnosa||"Data Diagnosa"))

			uDlglookupObj.inputvalue 	= "";
			uDlglookupObj.jenislookup 	= _JENISTOGGLE;
			setDlglookupshow(true)
			//--END LOOKUP--/
			return;
		} else return;

		const vTmpObj	= {
			togglejenis: _JENISTOGGLE,	
			toggleid: _IDITEM,	
			togglevalue: vValueedit,	
		}
		setDlgviewFSObj({...uDlgviewFSObj,...vTmpObj})
		setEfekview(!uEfekview);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			const vElfocus = document.getElementById("inpeditrmtoggle");
			vElfocus && vElfocus.focus();
		},450)
	}
	const hdlKDownEditRMinput = (_EV) => {
		if(_EV.keyCode === 13) {
			if (_EV.target.nodeName === "TEXTAREA") {
				if(_EV.shiftKey) return;
			}

			_EV.preventDefault();
			_EV.stopPropagation();
			apiProseseditRMitem();
			return;
		}

		if(_EV.keyCode === 27) {
			_EV.preventDefault();
			_EV.stopPropagation();
			initEditRMtoggleoff()
			return;
		}
	}
	const hdlKlikEditRMtogglesimpan = (_IDITEM,_JENISTOGGLE) => {
		//--VALIDASI--/
		//--END VALIDASI--/
		apiProseseditRMitem();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const initResettabel = () => {
		setJmlData(0);
		setJmlHal(1);
		setDatatabelArr([]);
		setRowselect(-1);
		setPageActive(1);
	}
	const initEditRMtoggleoff = () => {
		uDlgviewFSObj.togglejenis	= undefined;
		uDlgviewFSObj.toggleid		= undefined;
		uDlgviewFSObj.togglevalue	= undefined;
		setEfekview(!uEfekview);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentBack = () => {
		//if(uIsShowbatal && uIsShowperiksa) return ("");
		if(uISExtenderactive) return "";

		return (
			<CHeaderToggler className="px-0 text-white"
				onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>
		)
		//{(uIsShowbatal && uIsShowperiksa) && ("")}
	}
	const contentDropdownmobile = () => {
		if(uHtml500msg) return "";

		return(
		<>
		<CDropdown>
		<CDropdownToggle color="transparent" className="p-0" caret={false}>
			<CIcon icon="cilChevronBottom" className="classikonbox align-middle" height={30}/>
		</CDropdownToggle>

		<CDropdownMenu className="pt-0" placement="bottom-end">
			<CDropdownItem onClick={()=>hdlKlikReset()}>
			<CIcon className="me-2 classikontabel classikonreset" height={25}/>Reset Tampilan
			</CDropdownItem>

			{((uIsPeriksaallow && !uIsShowbatal) || !uIsPeriksaallow) && (
			<>
				{uTokenObj.userhak !== "DOKTER" && (
				<CDropdownItem onClick={()=>hdlKlikAntrian()}>
				<CImage className="me-2" src={pjson.homepage+"api/images/icon_booking.png"}
					height={25}/>{uBahasaObj.caption_reservasipasien||"Reservasi Pasien"}
				</CDropdownItem>
				)}
			</>
			)}

			<CDropdownItem onClick={()=>hdlKlikFotopasien()} className="classcursorpointer">
				<CImage src={pjson.homepage+"api/images/icon_foto.png"} className="align-middle me-2" height={25}/>
				{(uBahasaObj.caption_fotopasien||"Foto Pasien")}
			</CDropdownItem>

			<CDropdownDivider/>

			<CDropdownItem onClick={()=>hdlKlikPasien()}>
			<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2"
				height={25}/>{uBahasaObj.caption_lihatdetilpasien||"Detil Pasien"}
			</CDropdownItem>

			{(uIsPeriksaallow && uIsShowbatal) && (
			<>
				<CDropdownItem className="d-md-none" onClick={()=>hdlKlikBatalperiksa()}>
					<CImage src={pjson.homepage+"api/images/icon_periksabatal.png"} 
						className="mx-0 me-2"
						height={25}/>
					{uBahasaObj.caption_batalkanperiksa||"BATALKAN Periksa"}
				</CDropdownItem>
			</>
			)}
		</CDropdownMenu>
		</CDropdown>

		{(uIsPeriksaallow && uIsShowbatal) && (
			<CLink className="d-none d-md-inline-block" onClick={()=>hdlKlikBatalperiksa()}>
			<CImage src={pjson.homepage+"api/images/icon_periksabatal.png"} className="mx-1 align-middle" height={30}/>
			</CLink>
		)}
		</>
		)//->
	}
	const contentWarningheader = () => {
		if(uHtml500msg) return "";

		return (
		<>
		{(!uIsPeriksaallow) ? (
		<>
			{(uTokenObj.userhak === "DOKTER") && (
			<div className="mt-2 p-2 classfontsmaller d-none d-md-block"
				style={{backgroundColor:"#0000FF11",border:"2px dashed blue",borderRadius:7}}>
			<small className="fst-italic text-primary">
				Proses Billing PEMERIKSAAN, hanya dapat dilakukan Jika Pasien Merupakan Pasien Antrian HARI INI dan Sudah Berstatus HADIR..
				<span/>Silahkan Daftarkan Antrian Pasien, hubungi FO/PENGELOLA. 
			</small>
			</div>
			)}
		</>
		) : (
		<>
				{UFunc.isEmpty(uPeriksainitObj) === false && (
				<>
				{(!uIsShowbatal && !uIsShowperiksa) && (
					<div className="mt-2 p-2 classfontsmaller d-none d-md-block"
						style={{backgroundColor:"#0000FF11",border:"2px dashed blue",borderRadius:7}}>
					<small className="classblinking text-danger">
					Pasien Ini Masih Dalam Status Pemeriksaan..
					<span/>Untuk MEMBATALKAN, Hubungi PENGELOLA atau User yang Sedang Melakukan Billing Pemeriksaan pada Pasien Ini! 
					</small>
					</div>
				)}
				</>
				)}
		</>
		)}
		</>
		)//>
	}
	const contentHeadersub = () => {
		if(uHtml500msg) return (
		<div className="w-100 d-none d-lg-flex justify-content-between">
			<b>Oops, Sorry There is a Problems..</b>
			<div className="px-5">
			<CTooltip className="d-none d-lg-block" content={"--"+(uBahasaObj.caption_resetview||"Refresh Tampilan")}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
			</CTooltip>
			</div>
		</div>
		)//>

		if(uIsLoadprocessing) return (
			<i className="text-center text-primary classblinking w-100 d-none d-lg-block">Loading...</i>
		)//>

		const vTgllahirDT 	= (uDatapasienObj.tgl_lahir||"")===""?null:UFunc.toDate(uDatapasienObj.tgl_lahir);
		const vTglnowDT		= new Date();
		const vISBirthdaytoday = vTgllahirDT 
			? (vTgllahirDT.getDate() === vTglnowDT.getDate() &&
				vTgllahirDT.getMonth() === vTglnowDT.getMonth()) : false;

		return (
		<>
			<div className="d-none d-lg-block" style={{width:"80%"}}>
				<span className="text-info fw-bold align-middle">
					{uDatapasienObj.pasien_caption||"UNDF"}
				</span>
				<span className="mx-1">&middot;</span>
				<b style={{color:((uDatapasienObj.sex||"")==="P"?"pink":"navy")}}>
					[{uDatapasienObj.sex||"L"}]
				</b>
				{(uDatapasienObj.asuransi_caption||"")!=="" && (
				<>
					<span className="mx-1 d-none d-md-inline">&middot;</span>
					<CBadge color="light" className="d-none d-md-inline" style={{border:"1px solid purple"}}>
					<small className="fst-italic" style={{color:"purple"}}>{uDatapasienObj.asuransi_caption}</small>
					</CBadge>
				</>
				)}

				{vISBirthdaytoday && (
				<>
					<span className="mx-1">&middot;</span>
					<CTooltip className="d-none d-lg-block" content="--Pasien Berulang Tahun Hari Ini--">
					<CImage src={pjson.homepage+"api/images/icon_birthday.png"} className="classblinking" height={30}/>
					</CTooltip>
				</>
				)}

				{((uDatapasienObj.status_piutang||"TIDAK")==="YA") && (
				<>
					<span className="mx-1">&middot;</span>
					<CTooltip className="d-none d-lg-block" content={"--Ada Cicilan Pembayaran"}>
					<CImage src={pjson.homepage+"api/images/icon_piutang.png"} className="classblinking" height={32}/>
					</CTooltip>
				</>
				)}
				{((uDatapasienObj.antrian_ket ||"")!=="") && (
				<>
					<span className="mx-1">&middot;</span>
					<small className="text-primary fst-italic">{uDatapasienObj.antrian_ket}</small>
					<span className="mx-1">&middot;</span>
				</>
				)}

				<div className="classborderbottom my-2"/>

				<div className="classfontsmaller">
				<small>
				{uDatacabangArr.length > 1 && (<>{contentCabang()}</>)}

				<span className="mx-1">&middot;</span>
				{((uDatapasienObj.umur||"")!=="") && (
				<>
					<b className="">{uDatapasienObj.umur}</b>
					<span className="mx-1">&middot;</span>
				</>
				)}

				{((uDatapasienObj.tgl_input||"")!=="") && (
				<>
					Tgl Daftar: <b>{UFunc.WaktuAngka(uDatapasienObj.tgl_input)}</b>
					{uDatacabangArr.length > 1 && (
					<>
					<span className="me-1"/>
					({(()=>{
						const vArridx = UFunc.getObjectIndeks(uDatacabangArr,uDatapasienObj.id_cabang)
						return (uDatacabangArr[vArridx].cabang_nama)
					})()})
					</>
					)}
					<span className="mx-1">&middot;</span>
				</>
				)}

				{((uDatapasienObj.kondisi_khusus||"")!=="") && (
				<>
					<span className="text-success classblinking">{uDatapasienObj.kondisi_khusus}</span>
					<span className="mx-1">&middot;</span>
				</>
				)}
				</small>
				</div>

				{contentWarningheader()}
			</div>

			<div className="ps-2 d-none d-lg-block" style={{width:"20%"}}>
				{(uIsActionprocess) ? (
				<div className="text-center">
					<CSpinner className="align-middle" color="danger"/>
				</div>
				) : (
				<>
				<div className="d-lg-none">{contentDropdownmobile()}</div>

				<div className="d-none d-lg-flex justify-content-between align-items-center">
				<div className={"text-center"+((!uIsPeriksaallow) ? " w-100" : "")}>
					<CTooltip className="d-none d-lg-block" content={"--"+(uBahasaObj.caption_resetview||"Refresh Tampilan")}>
					<CLink onClick={()=>hdlKlikReset()} className="classikontabel classikonreset classcursorpointer"/>
					</CTooltip>
					
					<CTooltip className="d-none d-lg-block" content={"--"+(uBahasaObj.caption_fotopasien||"Foto Pasien")}>
					<CLink onClick={()=>hdlKlikFotopasien()} className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_foto.png"} className="align-middle mx-1" height={30}/>
					</CLink>
					</CTooltip>

					<CDropdown>
					<CDropdownToggle className="p-0" caret={false} color="transparent">
						<CIcon icon="cilChevronBottom" className="classikonbox" height={28}/>
					</CDropdownToggle>
					
					<CDropdownMenu>
					{((uIsPeriksaallow && !uIsShowbatal) || !uIsPeriksaallow) && (
					<>
						{(uTokenObj.userhak||"")!=="DOKTER" && (
						<CDropdownItem onClick={()=>hdlKlikAntrian()} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle me-2" height={25}/>
							{(uBahasaObj.caption_reservasipasien||"Reservasi Pasien")}
						</CDropdownItem>
						)}
					</>
					)}

					<CDropdownItem onClick={()=>hdlKlikPasien()} className="classcursorpointer">
						<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2" height={25}/>
						{(uBahasaObj.caption_lihatdetilpasien||"Data Pribadi Pasien")}
					</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
				</div>

				{(uIsPeriksaallow) && (
				<div>
					{(uIsShowbatal) && (
					<>
					<CTooltip className="d-none d-lg-block" content={"--BATALKAN Pemeriksaan"}>
					<CLink onClick={()=>hdlKlikBatalperiksa()} className="classcursorpointer">
					<CImage src={pjson.homepage+"api/images/icon_periksabatal.png"} height={40}/>
					</CLink>
					</CTooltip>
					</>
					)}

					{(uIsShowbatal && uIsShowperiksa) && (
						<span className="mx-1">&middot;</span>
					)}

					{(uIsShowperiksa) && (
					<CTooltip className="d-none d-lg-block" content={"--Billing PEMERIKSAAN"}>
					<CLink onClick={()=>hdlKlikBillingperiksa()} className="classcursorpointer">
					<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={40}/>
					<CIcon icon="cilChevronDoubleRight" height={13}/>
					</CLink>
					</CTooltip>
					)}
				</div>
				)}
				</div>
				</>
				)}
			</div>
		</>
		)//>

		return(
		<>					
				{(uIsPeriksaallow) ? (
				<>
					{(uIsActionprocess) ? (
					<>
					</>
					) : (
					<>

					{UFunc.isEmpty(uPeriksainitObj)!==true && (
					<>
						{(!uIsShowbatal && !uIsShowperiksa) && (
						<div className="text-center p-2 classfontsmaller"
							style={{backgroundColor:"#0000FF11",border:"2px dashed blue",borderRadius:7}}>
						<small className="classblinking text-danger">
						Pasien Ini Masih Dalam Status Pemeriksaan..
						<br/>Untuk MEMBATALKAN, Hubungi PENGELOLA atau User yang Sedang Melakukan Billing Pemeriksaan pada Pasien Ini! 
						</small>
						</div>
						)}
					</>
					)}
					</>
					)}
				</>
				) : (
				<>
					<div className="text-center p-2 px-3 classfontsmaller"
						style={{backgroundColor:"#0000FF11",border:"2px dashed blue",borderRadius:7}}>
					<small className="fst-italic text-primary">
					Proses Billing PEMERIKSAAN, hanya dapat dilakukan Jika Pasien Merupakan Pasien Antrian HARI INI dan Sudah Berstatus HADIR..
					<br/>Silahkan Daftarkan Antrian Pasien, hubungi FO/PENGELOLA. 
					<div className="d-none">{JSON.stringify(uPeriksainitObj)}</div>
					</small>
					</div>
				</>
				)}
		</>
		)//->
	}
	const contentHeadersubmobile = () => {
		if(uHtml500msg) return (
		<div className="w-100 d-flex d-lg-none justify-content-between">
			<b>Oops, Sorry There is a Problems..</b>
			<div className="px-5">
			<CTooltip className="d-none d-lg-block" content={"--"+(uBahasaObj.caption_resetview||"Refresh Tampilan")}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
			</CTooltip>
			</div>
		</div>
		)//->

		if(uIsLoadprocessing) return (
			<i className="text-center text-primary classblinking w-100 d-lg-none">Loading...</i>
		)//->

		const vTgllahirDT 	= (uDatapasienObj.tgl_lahir||"")===""?null:UFunc.toDate(uDatapasienObj.tgl_lahir);
		const vTglnowDT		= new Date();
		const vISBirthdaytoday = vTgllahirDT 
			? (vTgllahirDT.getDate() === vTglnowDT.getDate() &&
				vTgllahirDT.getMonth() === vTglnowDT.getMonth()) : false;

		return (
		<div className={"w-100"+(!isMobile?" d-lg-none":"")}>
			<div className="d-flex justify-content-between classborderbottom pb-2 mb-2">
				<div className="">
				<span className="text-info fw-bold align-middle">
					{UFunc.Usercaptionfilter(uDatapasienObj.pasien_caption||"UNDF")}
				</span>
				<span className="mx-1">&middot;</span>
				<b style={{color:((uDatapasienObj.sex||"")==="P"?"pink":"navy")}}>
					[{uDatapasienObj.sex||"L"}]
				</b>

				{vISBirthdaytoday && (
				<>
					<span className="mx-1">&middot;</span>
					<CTooltip className="d-none d-lg-block" content="--Pasien Berulang Tahun Hari Ini--">
					<CImage src={pjson.homepage+"api/images/icon_birthday.png"} className="classblinking" height={30}/>
					</CTooltip>
				</>
				)}
				</div>

				<div>
				{(uIsActionprocess) ? (
					<>
					<CSpinner className="align-middle" color="danger"/>
					</>
				) : (
				<>
				{contentDropdownmobile()} 

				{uIsPeriksaallow && (
					<>
					{(uIsShowperiksa) && (
					<CButton size="sm" color="transparent"
						onClick={()=>hdlKlikBillingperiksa()}
						className="fw-bold text-danger px-0"
						id="btnperiksa">
					<CImage src={pjson.homepage+"api/images/icon_periksa.png"} height={30}/>
					</CButton>
					)}
					</>
				)}
				</>
				)}
				</div>
			</div>

			<div className="classfontsmaller">
				<small>
				{uDatacabangArr.length > 1 && (<>{contentCabang()}</>)}

				<span className="mx-1">&middot;</span>
				{((uDatapasienObj.umur||"")!=="") && (
				<>
					<b className="">{uDatapasienObj.umur}</b>
					<span className="mx-1">&middot;</span>
				</>
				)}

				{((uDatapasienObj.tgl_input||"")!=="") && (
				<>
					Tgl Daftar: <b>{UFunc.WaktuAngka(uDatapasienObj.tgl_input)}</b>
					<span className="mx-1">&middot;</span>
				</>
				)}

				{((uDatapasienObj.kondisi_khusus||"")!=="") && (
				<span className="d-none d-md-inline">
					<span className="text-success classblinking">{uDatapasienObj.kondisi_khusus}</span>
					<span className="mx-1">&middot;</span>
				</span>
				)}
				</small>
			</div>

			{contentWarningheader()}
		</div>
		)//->
	}
	const contentTabel = () => {
		if(uHtml500msg) return (
			<div className="p-3 px-5">{UFunc.renderHTML(uHtml500msg)}</div>
		)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;

		const vDataviewArr	= uDatatabelArr.filter(vItems =>
			parseInt(vItems.pg||1) === parseInt(uPageActive));

		return (
		<>
		<div className="d-md-none"> 
		<table className="table table-borderless table-striped" id="idtabeldata">
		<thead>
		<tr>
		<th width={25} className="p-0"/>
		<th className="p-0"/>
		</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tgl_periksa,dokter_id,dokter_caption,anamnesa,items_arr,
				items_count,status_piutang,is_processing,init_expand,is_loading,
			} = vItems;

			vNumber++;
			const vCaptionupper	= (UFunc.TglAngka(tgl_periksa)+" :: "+(dokter_caption||"")).toUpperCase();

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
			<td className="px-1 pe-0">{vNumber}.</td>
			<td className="px-1">
			<CRow className="mx-0">
				<CCol xs="12" className="px-1 text-start d-flex justify-content-between">
					<div className="fw-bold">
					{UFunc.TglAngka(tgl_periksa)}
					{(status_piutang||"TIDAK")==="YA" && (
					<>
					<span className="mx-1">&middot;</span>
					<CImage src={pjson.homepage+"api/images/icon_piutang.png"}
						className="" height={25}/>
					</>
					)}
					</div>

					<div>
					{(is_loading||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CDropdown>
					<CDropdownToggle placement="bottom-end" color="transparent" className="p-0" caret={false}>
						<CIcon icon="cilOptions" className="" height={18}/>
					</CDropdownToggle>

					<CDropdownMenu className="pt-0" placement="bottom-end">
						<CDropdownItem onClick={()=>hdlKlikDetilRM(id)}>
						<CImage className="me-2" src={pjson.homepage+"api/images/icon_rekammedis.png"}
							height={25}/>Detil RM
						</CDropdownItem>

						{((uIsHapusallow||false)===true
							|| (uTokenObj.userhak==="DOKTER" 
								&& parseInt(uTokenObj.user_dokterid||"0") === parseInt(dokter_id||"0"))
						) && (
						<>
							<CDropdownDivider/>

							<CDropdownItem onClick={()=>hdlKlikEditRM(id)} className="classcursorpointer">
								<CIcon className="classikonedit align-middle me-2" height={25}/>
								{uBahasaObj.caption_editrekammedis||"Edit Rekam Medis"}
							</CDropdownItem>
								
							{((uIsHapusallow||false)===true && !uISDemoapp) && (
							<>
							<CDropdownItem onClick={()=>hdlKlikHapus(id)} className="classcursorpointer">
								<CIcon className="classikonhapus align-middle me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							</>
							)}
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
					</>
					)}
					</div>
				</CCol>

				<CCol xs="12" className="px-1 my-2 classborderbottom"/>

				<CCol xs="3" className="px-1 text-start text-primary">Dokter</CCol>
				<CCol xs="9" className="px-1 text-primary">{UFunc.Usercaptionfilter(dokter_caption,3)}</CCol>

				<CCol xs="4" className="px-1 text-start">Anamnesa</CCol>
				<CCol xs="8" className="px-1">{(anamnesa)}</CCol>

				<CCol xs="12" className="px-1 my-2 classborderbottom"/>

				<CCol xs="5" className="px-1 text-start">Jml Terapi</CCol>
				<CCol xs="7" className="px-1"><b>{UFunc.formatAngka(items_count)}</b> Terapi</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>

		<div className="d-none d-md-block"> 
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center classfontsmaller align-top">
			<th width="9%" className="text-start">{uBahasaObj.caption_tglperiksa||"Tgl Periksa"}</th>
			<th width="5%" style={{minWidth:50}} className="">&nbsp;</th>
			<th width="15%" className="text-start">{uBahasaObj.word_dokter||"Dokter"}</th>
			<th width="10%" className="text-start">{uBahasaObj.word_anamnesa||"Anamnesa"}</th>
			<th width="3%" className="">&nbsp;</th>
			<th className="px-0">
				<div className="d-flex text-start">
				<div className="px-1" style={{width:"25%"}}>{uBahasaObj.word_diagnosa||"Diagnosa"}</div>
				<div className="px-1" style={{width:"30%"}}>{uBahasaObj.word_tindakan||"Tindakan"}</div>
				<div className="px-1" style={{width:"45%"}}>{uBahasaObj.word_terapi||"Terapi"}</div>
				</div>
			</th>
			</tr>
			<tr className="d-none"><td colSpan={6}>{JSON.stringify(vDataviewArr)}</td></tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tgl_periksa,dokter_id,dokter_caption,anamnesa,items_arr,
				items_count,status_piutang,is_processing,init_expand,
				item_diagnosafirst,item_ketgigifirst,item_terapifirst,item_tindakanfirst,
				is_loading,
			} = vItems;

			const vNumber = vKeys + 1;
			const vCaptionupper	= (UFunc.TglAngka(tgl_periksa)+" :: "+(dokter_caption||"")).toUpperCase();

			const vItemsFilterArr = (init_expand||"TIDAK")==="YA" ? (items_arr||[])
				: (items_arr||[]).filter((vItemterapi,vKeyterapi)=>vKeyterapi <= 0); 

			const vStyleexpand	= (init_expand||"TIDAK")==="YA" ? "" : " text-muted fst-italic";

			//console.log("(Rekammedis - contentTabel) vItemsFilterArr => "+JSON.stringify(vItemsFilterArr))
			return (
			<tr id={"idtr"+id} key={vKeys} className={"classfontsmaller align-top text-start "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1">
				<span className="text-center d-inline-block">
				<b>{UFunc.TglAngka(tgl_periksa)}</b>
				{(status_piutang||"TIDAK")==="YA" && (
				<>
				<div>
				<CTooltip className="d-none d-lg-block" content={"--Belum Lunas: "+vCaptionupper}>
				<CImage src={pjson.homepage+"api/images/icon_piutang.png"}
					className=""
					height={23}/>
				</CTooltip>
				</div>
				</>
				)}
				</span>
			</td>

			<td className="px-0 text-center">
				{(is_loading||false)===true ? (
					<CSpinner color="primary" size="sm"/>
				) : (
				<>
				{((uIsHapusallow||false)===true
					|| (uTokenObj.userhak==="DOKTER" 
						&& parseInt(uTokenObj.user_dokterid||"0") === parseInt(dokter_id||"0"))
				) ? (
				<>
					<CDropdown>
					<CDropdownToggle caret={false}
						className="p-0" 
						color="transparent">
						<CIcon icon="cilChevronBottom" className="classikonbox mx-0" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu className="px-0">
						<CDropdownItem onClick={()=>hdlKlikDetilRM(id)} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle me-2" height={25}/>
							Detil Rekam Medis
						</CDropdownItem>

						{((uIsHapusallow||false)===true
							|| (uTokenObj.userhak==="DOKTER" 
								&& parseInt(uTokenObj.user_dokterid||"0") === parseInt(dokter_id||"0"))
						) && (
						<>
							<CDropdownDivider/>

							<CDropdownItem onClick={()=>hdlKlikEditRM(id)} className="classcursorpointer">
								<CIcon className="classikonedit align-middle mx-0 me-2" height={25}/>
								{uBahasaObj.caption_editrekammedis||"Edit Rekam Medis"}
							</CDropdownItem>
								
							{((uIsHapusallow||false)===true && !uISDemoapp) && (
							<>
							<CDropdownItem onClick={()=>hdlKlikHapus(id)} className="classcursorpointer">
								<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							</>
							)}
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
				</>
				) : (
					<CTooltip className="d-none d-lg-block" content={"--Detil RM: "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikDetilRM(id)} className="classcursorpointer">
					<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} height={27}/>
					</CLink>
					</CTooltip>
				)}
				</>
				)}
			</td>

			<td className="px-1 text-primary">
				{dokter_caption||"UNDF, DRG"}
			</td>

			<td className="px-1">{anamnesa||"-"}</td>

			<td className="px-1">
				{parseInt(items_count||1) > 1 ? (
					<>
					{(is_processing||false)===true ? (
						<CSpinner color="danger" size="sm"/>
					) : (
					<CTooltip className="d-none d-lg-block" content="--EXPAND/Collapse Data Terapi">
					<CLink onClick={()=>hdlKlikToggleterapi(id)}
						className="classcursorpointer">
					<CIcon icon={(init_expand||"TIDAK")==="YA"?"cilArrowTop":"cilArrowBottom"} 
						className={(init_expand||"TIDAK")==="YA"?"text-black":"text-primary"} 
						height={20}/>
					</CLink>
					</CTooltip>
					)}
					</>
				) : (<>&nbsp;</>)
				}
			</td>
			
			<td className="px-0">
				{vItemsFilterArr.map((vItemterapi,vKeyterapi)=>{
				return (
				<div className={"d-flex"+
					vStyleexpand+
					(vKeyterapi<=0?" pb-1":" py-1")+
					(vKeyterapi<vItemsFilterArr.length-1?" classborderbottom":"")}
					 key={vKeyterapi}>
					<div className="px-1" style={{width:"25%"}}>
					{((vItemterapi.diagnosa||"")==="" && (vItemterapi.ket_gigi||"")==="") && (
						<>-</>
					)}

					{(vItemterapi.diagnosa||"")!=="" && (
					<div>{vItemterapi.diagnosa||"-"}</div>
					)}

					{(vItemterapi.ket_gigi||"")!=="" && (
					<div><small className="fst-italic">({vItemterapi.ket_gigi})</small></div>
					)}
					</div>
				
					<div className="px-1 fst-italic" style={{width:"30%"}}>
						{(init_expand||"TIDAK")==="YA" ? (
						<span style={{color:"maroon"}}>{UFunc.renderHTML(vItemterapi.tindakan||"-")}</span>
						) : (
						<span>{UFunc.renderHTML(UFunc.Usercaptionfilter(vItemterapi.tindakan,4))}...</span>
						)}
					</div>
				
					<div className="px-1" style={{width:"45%"}}>
					<ol start={vKeyterapi+1} className="m-0 ps-3">
					<li className="m-0">
					<div className="d-flex justify-content-between">
						{(init_expand||"TIDAK")==="YA" ? (
						<>
						<div style={{maxWidth:"63%"}} className="">{vItemterapi.terapi_caption}</div>
						<div className="text-end">(
							<small className="text-primary fw-bolder">{UFunc.formatAngka(vItemterapi.qty)}</small>
							<small className="d-none d-lg-inline">unt</small>
							{" "}x{" "}
							<small className="text-success">Rp{UFunc.formatAngka(vItemterapi.terapi_biaya)}</small>
						)</div>
						</>
						) : (
						<span className="">{UFunc.Usercaptionfilter(vItemterapi.terapi_caption,4)}...</span>
						)}
					</div>						
					</li>
					</ol>
					</div>
				</div>
				)
				})}
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>
		</>
		)//->
	}
	const contentCabang = () => {
		if(uHtml500msg) return ""

		/*const vCabangIDallowedArr = uTokenObj.user_cabangarr || [];
		const vCabangfilterArr = uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=> vCabangIDallowedArr.includes(vItems.id.toString()));
		//-*/
		const vCabangfilterArr = uDatacabangArr;

		if(isMobile) return (
		<CDropdown className="">
			<CDropdownToggle color="light border rounded" className="py-0">
			{(()=>{
				const vArridx = UFunc.getObjectIndeks(vCabangfilterArr,uInpcabangid);
				return (vCabangfilterArr[vArridx]||{}).cabang_kode||"UNDF";
			})()}
			</CDropdownToggle>

			<CDropdownMenu>
			{vCabangfilterArr.map((vItems,vKeys)=>{
				return (
				<CDropdownItem onClick={()=>setInpcabangid(vItems.id)}
					key={vKeys}>
					{vItems.cabang_kode}
					<span className="mx-2">&middot;</span>
					{vItems.cabang_nama}
				</CDropdownItem>
				)
			})}
			</CDropdownMenu>
		</CDropdown>
		)

		return (
		<>
		<CFormSelect size="sm"
			value={uInpcabangid}
			onChange={(e)=>{setInpcabangid(e.target.value);}}
			className="d-none d-md-inline w-auto py-1"
			id="inpcabangid">
		{vCabangfilterArr.map((vItems,vKeys)=>{
			return (
			<option key={vKeys} value={vItems.id}>{vItems.cabang_nama}</option>
			)
		})}
		</CFormSelect>

		<CDropdown className="d-md-none">
			<CDropdownToggle color="light border rounded" className="py-0">
			{(()=>{
				const vArridx = UFunc.getObjectIndeks(vCabangfilterArr,uInpcabangid);
				return (vCabangfilterArr[vArridx]||{}).cabang_kode||"UNDF";
			})()}
			</CDropdownToggle>

			<CDropdownMenu>
			{vCabangfilterArr.map((vItems,vKeys)=>{
				return (
				<CDropdownItem onClick={()=>setInpcabangid(vItems.id)}
					key={vKeys}>
					{vItems.cabang_kode}
					<span className="mx-2">&middot;</span>
					{vItems.cabang_nama}
				</CDropdownItem>
				)
			})}
			</CDropdownMenu>
		</CDropdown>
		</>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>);//->

		if(uDlgviewObj.jenis === "DETIL") return contentViewdetil();

		return (<></>)//->
	}
	const contentViewdetil = () => {
		if(uDlgviewObj.jenis !== "DETIL") return (<></>)//->

		const vArridx 		= UFunc.getObjectIndeks(uDatatabelArr,uIDselect);

		const vPerawatArr	= uDatatabelArr[vArridx].perawat_arr||[];
		const vTerapiArr	= uDatatabelArr[vArridx].items_arr||[];
		const vNilainetto	= parseFloat(uDatatabelArr[vArridx].total_bruto||"0") - 
			parseFloat(uDatatabelArr[vArridx].diskon||"0") - 
			parseFloat(uDatatabelArr[vArridx].nilai_asuransi||"0")
		
		const vBayarArr			= uDatatabelArr[vArridx].pembayaran_arr||[];
		const vBayarasuransiArr	= uDatatabelArr[vArridx].asuransi_arr||[];

		const vSUMbayar 		= vBayarArr.reduce((n, {nilai_bayar}) => n + parseFloat(nilai_bayar||"0"), 0)
		const vSUMBayarasuransi	= vBayarasuransiArr.reduce((n, {nilai_bayar}) => n + parseFloat(nilai_bayar||"0"), 0)
		return (
		<small>
		<CRow>
		<CCol lg="6" className="">
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1"><small>Data Rekam Medis</small></CCardHeader>
			<CCardBody>
				<CRow>
				<CCol xs="5">Tgl Periksa</CCol>
				<CCol xs="7" className="text-end">{UFunc.HariAngka(uDatatabelArr[vArridx].tgl_periksa)}</CCol>
				</CRow>
			
				<CRow>
				<CCol xs="4">Dokter</CCol>
				<CCol xs="8" className="text-primary text-end">
					{uDatatabelArr[vArridx].dokter_caption}
				</CCol>
				</CRow>
			
				<CRow>
				<CCol xs="4">Perawat</CCol>
				<CCol xs="8" className="text-end">
				{vPerawatArr.length <= 0 && (<>-</>)}
				{vPerawatArr.map((vItems,vKeys)=>{
					return (
					<span key={vKeys}>
					<i>{vItems.perawat_caption||"UNDF"}</i>
					{vKeys < vPerawatArr.length-1 && (<> &middot; </>)}
					</span>
					)
				})}
				</CCol>
				</CRow>
			
				<div className="my-2 classborderbottom"/>
			
				{parseInt(uDatatabelArr[vArridx].id_asuransi||"0") > 0 && (
				<CRow>
				<CCol xs="4">Asuransi</CCol>
				<CCol xs="8" className="text-end" style={{color:"purple"}}>{uDatatabelArr[vArridx].nama_asuransi||"-"}</CCol>
				</CRow>
				)}
			
				<CRow>
				<CCol xs="4">Anamnesa</CCol>
				<CCol xs="8" className="text-end">{uDatatabelArr[vArridx].anamnesa||"-"}</CCol>
				</CRow>
			</CCardBody>
			</CCard>

			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1"><small>Data Terapi</small></CCardHeader>
			<CCardBody>
				<ol className="mx-0 ps-3">
				{vTerapiArr.map((vItems,vKeys)=>{
					return (
					<li key={vKeys} className="mb-2 pb-2 px-0" style={{borderBottom:"1px dashed black",}}>
					<div className="d-flex justify-content-between classborderbottom pb-2 mb-2">
						<div>{vItems.terapi_caption}</div>
						<div className="text-success">
							Rp{UFunc.formatAngka(parseFloat(vItems.terapi_biaya||0) * parseInt(vItems.qty||1))}
						</div>
					</div>

					{parseInt(vItems.qty||1) > 1 && (
					<div className="d-flex justify-content-between classfontsmaller">
						<b>Rincian Item</b>
						<div className="ps-1 text-end">(
							<b className="text-primary">{vItems.qty}</b>unt
							<span className="mx-1">x</span>
							{UFunc.formatAngka(vItems.terapi_biaya)}
						)</div>
					</div>
					)}

					<div className="my-1 d-flex justify-content-between">
						<b>Diagnosa</b>
						<div className="ps-1 text-end">
							<div>{vItems.diagnosa || "-"}</div>

							{(vItems.diagnosa_ket||"")!=="" && (
							<div className="fst-italic d-block text-capitalize">
							{vItems.diagnosa_ket}
							</div>
							)}
						</div>
					</div>

					{(vItems.ket_gigi||"")!=="" && (
					<div className="my-1 d-flex justify-content-between">
						<b>No.Gigi</b>
						<div className="ps-1 text-end">
							<i>{vItems.ket_gigi || "-"}</i>
						</div>
					</div>
					)}

					{((vItems.tindakan||"")!=="") && (
					<div className="my-1 d-flex justify-content-between"
						style={{color:"Maroon"}}>
						<b>Tindakan</b>
						<div className="ps-1 text-end">{UFunc.renderHTML(vItems.tindakan)}</div>
					</div>
					)}
					</li>
					)
				})}
				</ol>
			</CCardBody>
			</CCard>

			{((uDatatabelArr[vArridx].note_dokter||"")!=="" && (uDatatabelArr[vArridx].note_nota||"")!=="") && (
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1"><small>{uBahasaObj.caption_catatan_pasien||"Catatan Pasien"}</small></CCardHeader>
			<CCardBody>
				<div className="fw-bold">{uDatatabelArr[vArridx].note_dokter||""}</div>
				{(uDatatabelArr[vArridx].note_nota||"")!=="" && (
				<>
				<div className="classborderbottom my-1"/>
				<small className="fst-italic d-block text-end">{uDatatabelArr[vArridx].note_nota||""}</small>
				</>
				)}
			</CCardBody>
			</CCard>
			)}
		</CCol>

		<CCol className="">
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1"><small>Data Transaksi</small></CCardHeader>
			<CCardBody>
				<CRow>
				<CCol xs="5">Nilai Transaksi</CCol>
				<CCol xs="7" className="text-end">{UFunc.formatAngka(uDatatabelArr[vArridx].total_bruto||0)}</CCol>
				</CRow>

				<CRow>
				<CCol xs="5">Diskon</CCol>
				<CCol xs="7" className="text-end"><span className="text-danger">{UFunc.formatAngka(uDatatabelArr[vArridx].diskon||0)}</span></CCol>
				</CRow>

				{(parseInt(uDatatabelArr[vArridx].id_asuransi||"0") > 0 && 1==3) && (
				<CRow>
				<CCol xs="5">Klaim Asuransi</CCol>
				<CCol xs="7" className="text-end" style={{color:"purple"}}>
					{UFunc.formatAngka(uDatatabelArr[vArridx].nilai_asuransi||0)}
				</CCol>
				</CRow>
				)}

				<div className="my-2 classborderbottom"/>

				<CRow>
				<CCol xs="5">Tagihan Pasien</CCol>
				<CCol xs="7" className="text-end">
					<b className="text-success">{UFunc.formatAngka(vNilainetto)}</b>
				</CCol>
				</CRow>

				<CRow>
				<CCol xs="9" md="7" lg="8">
					Terbayar<small className="ms-1">(PASIEN)</small>
					<span className="mx-1">&middot;</span>
					{(uDatatabelArr[vArridx].status_piutang||"TIDAK")!=="YA" ? (
					<span className="text-primary classfontsmaller">LUNAS</span>
					) : (
					<b className="text-danger classblinking classfontsmaller">BELUM LUNAS</b>
					)}
				</CCol>
				<CCol className="text-end">
					{(uDatatabelArr[vArridx].status_piutang||"TIDAK")!=="YA" ? (
					<>
					{UFunc.formatAngka(vSUMbayar)}
					</>
					) : (
					<>
					<b className="text-primary">{UFunc.formatAngka(vSUMbayar)}</b>
					</>
					)}
				</CCol>
				</CRow>
			</CCardBody>
			</CCard>

			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1"><small>Pembayaran Pasien</small></CCardHeader>
			<CCardBody>
			{vBayarArr.length > 0 && (
				<ol className="mx-0 ps-3">
				{vBayarArr.map((vItems,vKeys)=>{
					return (
					<li key={vKeys}>
					<div className="d-flex justify-content-between">
						<span>{UFunc.TglAngka(vItems.tgl_bayar)} &middot; (<b>{vItems.no_nota||""}</b>)</span>
						<div className="text-primary">Rp {UFunc.formatAngka(vItems.nilai_bayar)}</div>
					</div>
					</li>
					)
				})}
				</ol>
			)}
			</CCardBody>
			</CCard>

			{(parseInt(uDatatabelArr[vArridx].id_asuransi||"0") > 0 && 1==3) && (
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1"><small>Histori Klaim Asuransi</small></CCardHeader>
			<CCardBody>
			{vBayarasuransiArr.length > 0 && (
			<>
				<ol className="mx-0 ps-3">
				{vBayarasuransiArr.map((vItems,vKeys)=>{
					return (
					<li key={vKeys}>
					<div className="d-flex justify-content-between">
						<div>
							<div>{UFunc.TglAngka(vItems.tgl_bayar)}</div>
							{(vItems.keterangan||"")!=="" && (
							<small>(<i>{vItems.keterangan||""}</i>)</small>
							)}
						</div>
						<div className="text-primary">Rp {UFunc.formatAngka(vItems.nilai_bayar)}</div>
					</div>
					</li>
					)
				})}
				</ol>

				<div className="d-flex justify-content-between fw-bold border-top pt-2">
					<span>JML Bayar Klaim</span>
					<span>Rp {UFunc.formatAngka(vSUMBayarasuransi)}</span>
				</div>
			</>
			)}
			</CCardBody>
			</CCard>
			)}
		</CCol>
		</CRow>
		</small>
		)
	}
	const contentDlgviewFS = () => {
		if(!uISDlgviewFSshow) return (<></>)//>

		if((uDlgviewFSObj.is_loading||false)=== true) return (
		<div className="my-3 d-flex justify-content-center align-items-center"
			style={{minHeight:400}}>
			<CSpinner color="primary"/>
		</div>
		)//>

		const contentTerapi = (_IDRMITEM) => {
			const vArridx = UFunc.getObjectIndeks((uDlgviewFSObj.items_arr||[]),_IDRMITEM,"id_item");
			const vRowterapiObj	= (uDlgviewFSObj.items_arr||[])[vArridx] || {};

			return (
			<>
			<CRow className="mx-0">
				<CCol xs="9" className="px-0 px-md-2">
					{vRowterapiObj.terapi_caption}
					<span className="mx-1 d-none d-md-inline-block">&middot;</span>
					<span className="d-block d-md-inline">(
					<b className="text-primary">{vRowterapiObj.qty}</b> Unit x Rp <span className="text-success">{UFunc.formatAngka(vRowterapiObj.terapi_biaya)}</span>
					)</span>
				</CCol>
				<CCol xs="3" className="text-end px-1 px-md-2">{UFunc.formatAngka(parseFloat(vRowterapiObj.terapi_biaya)*parseFloat(vRowterapiObj.qty))}</CCol>
			</CRow>
			<div className="classborderbottom my-2"></div>

			<CRow className="mx-0 my-1">
				<CCol xs="3" md="4" className="px-0 px-md-2 fw-bolder">Diagnosa</CCol>
				<CCol xs="9" md="8" className="text-end px-1 px-md-2">
					<div className="d-flex justify-content-end align-items-start fst-italic ">
					<div style={{maxWidth:"85%"}}>{(vRowterapiObj.diagnosa||"")}</div>
				
					<div>
					<span className="mx-md-1">&middot;</span>
					{(uDlgviewFSObj.satusehat_status||"BELUM")!=="SUDAH" ? (
					<CTooltip className="d-none d-lg-block" content={"--Edit Diagnosa No-("+(vArridx+1)+")"}>
					<CLink className="classcursorpointer" onClick={()=>hdlKlikEditRMtoggleon(_IDRMITEM,"DIAGNOSA")}>
						<CIcon icon="cilPencil" className="classikonbox m-0" height={23}/>
					</CLink>
					</CTooltip>
					) : (
						<CIcon icon="cilBan" className="align-middle text-danger" height={23}/>
					)}
					</div>
					</div>
				</CCol>
			</CRow>

			<CRow className="mx-0 my-1">
				<CCol xs="3" md="4" className="px-0 px-md-2">Ket.Diagnosa</CCol>
				
				{(uDlgviewFSObj.togglejenis==="DIAGNOSAKET" 
					&& (uDlgviewFSObj.toggleid||"0").toString()===(_IDRMITEM).toString()
				) ? (
				<CCol xs="12" md="8" className="text-end px-0 px-md-2 mb-2">
					{(uDlgviewFSObj.is_processing||false)===true ? (
						<CSpinner color="primary" className="mx-2" size="sm"/>
					) : (
					<CInputGroup size="sm">
					<CFormInput size="sm" maxLength={255}
						value={uDlgviewFSObj.togglevalue||vRowterapiObj.diagnosa_ket}
						onChange={(e)=>{uDlgviewFSObj.togglevalue=e.target.value;setEfekview(!uEfekview)}}
						onKeyDown={e=>hdlKDownEditRMinput(e)} 
						id="inpeditrmtoggle"/>
					<CButton size="sm" color="dark" 
						onClick={()=>hdlKlikEditRMtogglesimpan()}
						id="btneditrmsimpan">
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
					)}
				</CCol>
				) : (
				<CCol xs="9" md="8" className="text-end px-1 px-md-2">
					<div className="d-flex justify-content-end align-items-start fst-italic">
					<div>{UFunc.renderHTML(vRowterapiObj.diagnosa_ket||"")}</div>
				
					<div>
					<span className="mx-md-1">&middot;</span>
					<CTooltip className="d-none d-lg-block" content={"--Edit Ket.Diagnosa No-("+(vArridx+1)+")"}>
					<CLink className="classcursorpointer" onClick={()=>hdlKlikEditRMtoggleon(_IDRMITEM,"DIAGNOSAKET")}>
						<CIcon icon="cilPencil" className="classikonbox m-0" height={23}/>
					</CLink>
					</CTooltip>
					</div>
					</div>
				</CCol>
				)}
			</CRow>

			<CRow className="mx-0 my-1">
				<CCol xs="3" md="4" className="px-0 px-md-2 fw-bolder">No.Gigi</CCol>
				
				{(uDlgviewFSObj.togglejenis==="KETGIGI" 
					&& (uDlgviewFSObj.toggleid||"0").toString()===(_IDRMITEM).toString()
				) ? (
				<CCol xs="12" md="8" className="text-end px-0 px-md-2 mb-2">
					{(uDlgviewFSObj.is_processing||false)===true ? (
						<CSpinner color="primary" className="mx-2" size="sm"/>
					) : (
					<CInputGroup size="sm">
					<CFormInput size="sm" maxLength={255}
						value={uDlgviewFSObj.togglevalue||vRowterapiObj.ket_gigi}
						onChange={(e)=>{uDlgviewFSObj.togglevalue=e.target.value;setEfekview(!uEfekview)}}
						onKeyDown={e=>hdlKDownEditRMinput(e)} 
						id="inpeditrmtoggle"/>
					<CButton size="sm" color="dark" 
						onClick={()=>hdlKlikEditRMtogglesimpan()}
						id="btneditrmsimpan">
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
					)}
				</CCol>
				) : (
				<CCol xs="9" md="8" className="text-end px-1 px-md-2">
					<div className="d-flex justify-content-end align-items-start fst-italic">
					<div>{(vRowterapiObj.ket_gigi||"")}</div>
				
					<div>
					<span className="mx-md-1">&middot;</span>
					<CTooltip className="d-none d-lg-block" content={"--Edit Ket.Gigi No-("+(vArridx+1)+")"}>
					<CLink className="classcursorpointer" onClick={()=>hdlKlikEditRMtoggleon(_IDRMITEM,"KETGIGI")}>
						<CIcon icon="cilPencil" className="classikonbox m-0" height={23}/>
					</CLink>
					</CTooltip>
					</div>
					</div>
				</CCol>
				)}
			</CRow>

			<CRow className="mx-0 my-1">
				<CCol xs="3" md="4" style={{color:"maroon"}} className="px-0 	px-md-2 fw-bolder">Tindakan</CCol>
				
				{(uDlgviewFSObj.togglejenis==="TINDAKAN" 
					&& (uDlgviewFSObj.toggleid||"0").toString()===(_IDRMITEM).toString()
				) ? (
				<CCol xs="12" md="8" className="text-end px-0 px-md-2 mb-2">
					{(uDlgviewFSObj.is_processing||false)===true ? (
						<CSpinner color="primary" className="mx-2" size="sm"/>
					) : (
					<CInputGroup size="sm" className="align-items-start">
					<CFormTextarea size="sm" rows={4}
						value={UFunc.br2nl(uDlgviewFSObj.togglevalue||vRowterapiObj.tindakan)}
						onChange={(e)=>{uDlgviewFSObj.togglevalue=UFunc.nl2br(e.target.value);setEfekview(!uEfekview)}}
						onKeyDown={e=>hdlKDownEditRMinput(e)} 
						id="inpeditrmtoggle"/>
					<CButton size="sm" color="dark" 
						onClick={()=>hdlKlikEditRMtogglesimpan()}
						id="btneditrmsimpan">
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
					)}
				</CCol>
				) : (
				<CCol xs="9" md="8" style={{color:"maroon"}} className="text-end px-1 px-md-2">
					<div className="d-flex justify-content-end align-items-start fst-italic">
					<div>{UFunc.renderHTML(vRowterapiObj.tindakan||"")}</div>
				
					<div>
					<span className="mx-md-1">&middot;</span>
					<CTooltip className="d-none d-lg-block" content={"--Edit Tindakan No-("+(vArridx+1)+")"}>
					<CLink className="classcursorpointer" onClick={()=>hdlKlikEditRMtoggleon(_IDRMITEM,"TINDAKAN")}>
						<CIcon icon="cilPencil" className="classikonbox m-0" height={23}/>
					</CLink>
					</CTooltip>
					</div>
					</div>
				</CCol>
				)}
			</CRow>
			</>
			)//>
		}

		return (
		<CRow>
		<div className="d-none">{JSON.stringify(uDlgviewFSObj)}</div>

		<CCol lg="6">
			<CCard className="classbgcard mb-3">
			<CCardHeader className="classfontsmaller py-0 pt-1">Data Rekam Medis</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between my-2">
				<div>
					<span className="d-none d-md-block">Tanggal Periksa</span>
					<small className="d-md-none">Tgl Periksa</small>
				</div>
				<b className="text-end">{UFunc.TglAngka(uDlgviewFSObj.tgl_periksa)}</b>
				</div>

				<div className="my-3 classborderbottom"/>
				
				<div className="d-flex justify-content-between my-2">
				<div>
					<span className="d-none d-md-block">Nama Dokter</span>
					<small className="d-md-none">Dokter</small>
				</div>
				<div className="text-end">
					<b className="text-primary">{(uDlgviewFSObj.dokter_caption || "Drg.Undf")}</b>
					{(uIsHapusallow && (uDlgviewFSObj.satusehat_status||"BELUM")!=="SUDAH") && (
					<>
					<span className="mx-md-1 d-none">&middot;</span>
					<CTooltip className="d-none d-md-block" content="--Edit Nama Dokter">
					<CLink onClick={()=>hdlKlikEditRMtoggleon(0,"DOKTER")} className="d-none classcursorpointer">
						<CIcon icon="cilPencil" className="classikonbox mx-0" height={25}/>
					</CLink>
					</CTooltip>
					</>
					)}
				</div>
				</div>
				
				<div className="d-flex justify-content-between my-2">
				<div>
					<span className="d-none d-md-block">Anamnesa</span>
					<small className="d-md-none">Anamnesa</small>
				</div>
				<div className="text-end">
					<b>{(uDlgviewFSObj.anamnesa || "Undf")}</b>
					{(uDlgviewFSObj.satusehat_status||"BELUM")!=="SUDAH" && (
					<>
					<span className="mx-md-1">&middot;</span>
					<CTooltip className="d-none d-lg-block" content="--Edit Anamnesa">
					<CLink onClick={()=>hdlKlikEditRMtoggleon(0,"ANAMNESA")} className="classcursorpointer">
						<CIcon icon="cilPencil" className="classikonbox mx-0" height={25}/>
					</CLink>
					</CTooltip>
					</>
					)}
				</div>
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol>
			<CCard className="classbgcard mb-3">
			<CCardHeader className="classfontsmaller py-0 pt-1">Item Terapi</CCardHeader>
			<CCardBody className="px-0 px-md-3">
				<table className="table table-borderless" id="idtabelitem">
				<thead>
				<tr>
				<th width={25} className="p-0"/>
				<th className="p-0"/>
				</tr>
				</thead>
				<tbody>
				{(uDlgviewFSObj.items_arr||[]).map((vItems,vKeys)=>{
					const {
						tindakan,ket_gigi,diagnosa,
						terapi_caption,terapi_biaya,qty,id_item
					} = vItems;

					return (
					<tr className="align-top"
						style={{borderBottom:"1px dashed black",}} 
						key={vKeys}>
					<td className="px-0 pe-1 px-md-1 text-end">{vKeys+1}.</td>
					<td className="px-0 px-md-1">
					<div className="d-md-none classfontsmaller">
						{contentTerapi(id_item)}
					</div>

					<div className="d-none d-md-block">
						{contentTerapi(id_item)}
					</div>
					</td>
					</tr>
					)
				})}
				</tbody>
				</table>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoadawal = () => {
		setHtml500msg();

		setDatapasienObj({})
		setLoadprocessing(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Rekammedis - apiLoadawal) LEWAT ");
		let vTimeout = setTimeout(()=>{
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_data";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.tampil) {
				setDatapasienObj(JSON.parse(output_string.datapasien||"{}"))
				setInpcabangid(parseInt(uTokenObj.user_cabangid||"0")); return;
				//apiLoadtabel();
			} else {
				setLoadprocessing(false);
				if(output_string.info) {
					setHtml500msg(output_string.info);
				} else if(output_string.errors) {
					console.log("(Rekammedis - apiLoadawal) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setLoadprocessing(false);
			setLoading(false);
			console.log("(Rekammedis - apiLoadawal) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadtabel = (_PAGE) => {
		_PAGE	= parseInt(_PAGE)||0;if(_PAGE < 0) return

		const vHandelview	= (_PAGE <= 0|| uIsTabelfistload) ?"YA":"TIDAK";
		let vDatatabelArr 	= uDatatabelArr;
		if(vHandelview==="YA") {
			vDatatabelArr	= [];
		} else {
			if(vDatatabelArr.length >= 10000) {
				vDatatabelArr	= uDatatabelArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatatabelArr	= uDatatabelArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Rekammedis - apiLoadtabel) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vPage = _PAGE <= 0 ? 1 : _PAGE;
		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_cabangid : uInpcabangid,
			send_pg: vPage,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_rekammedis";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.datatabel||"[]")
				vTmpArr.map(vItems => {
					vItems.init_expand = parseInt(vItems.items_count)===1 ? "YA" : vItems.init_expand;
					vItems.pg = vPage;
				});

				setDatatabelArr([...vDatatabelArr,...vTmpArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData))

				setTabelfistload(false);
				
				setTimeelapse(vTimeDiff);
				setLoadprocessing(false);
			} else {
				setLoadprocessing(false);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Rekammedis - apiLoadtabel) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setLoadprocessing(false);
			setLoading(false);
			console.log("(Rekammedis - apiLoadtabel) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL)||1;if(_IDTABEL <= 0) return

		const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		uDatatabelArr[vArridx].is_processing = true;

		/*//--TESTING_FRONTEND--/
		console.log("(Rekammedis - apiLoaditem) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_rekammedisid : _IDTABEL,
			send_cabangid : uInpcabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_item";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDatatabelArr[vArridx].is_processing 	= false;
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataitem || "[]")
				uDatatabelArr[vArridx].items_arr 	= vTmpArr || [];
				uDatatabelArr[vArridx].items_count 	= (vTmpArr || []).length;
				
				uDatatabelArr[vArridx].init_expand = (uDatatabelArr[vArridx].init_expand||"TIDAK")==="TIDAK"
					? "YA" :"TIDAK";
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedis - apiLoaditem) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			uDatatabelArr[vArridx].is_processing 	= false;
			console.log("(Rekammedis - apiLoaditem) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaditemedit = (_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return

		const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		uDatatabelArr[vArridx].is_loading = true;

		/*//--TESTING_FRONTEND--/
		console.log("(Rekammedis - apiLoaditemedit) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_rekammedisid : _IDTABEL,
			send_cabangid : uInpcabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_item";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDatatabelArr[vArridx].is_loading 	= false;
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataitem || "[]")
				uDatatabelArr[vArridx].items_arr 	= vTmpArr || [];
				uDatatabelArr[vArridx].items_count 	= (vTmpArr || []).length;

				uDlgviewFSObj.items_arr 	= vTmpArr;
				setDlgviewFSshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedis - apiLoaditemedit) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			uDatatabelArr[vArridx].is_loading 	= false;
			console.log("(Rekammedis - apiLoaditemedit) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL)||1;if(_IDTABEL <= 0) return

		const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL)
		uDatatabelArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		const vItemscount	= parseInt(uDatatabelArr[vArridx].items_count || 1);
		const vItemsArr		= uDatatabelArr[vArridx].items_arr || [];
		const vItemload		= vItemscount !== vItemsArr.length ? "YA" : "TIDAK";				

		/*//--TESTING_FRONTEND--/
		console.log("(Rekammedis - apiLoaddetil) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_rekammedisid : _IDTABEL,
			send_cabangid : uInpcabangid,
			send_itemload: vItemload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_detil";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDatatabelArr[vArridx].is_loading = false;
			if(output_string.tampil) {
				let vTmpArr;
				if(vItemload==="YA") {
					vTmpArr = JSON.parse(output_string.dataitem||"[]")
					uDatatabelArr[vArridx].items_arr	= vTmpArr;
					uDatatabelArr[vArridx].items_count 	= vTmpArr.length;
				}

				uDatatabelArr[vArridx].pembayaran_arr= JSON.parse(output_string.datapembayaran||"[]");
				uDatatabelArr[vArridx].asuransi_arr	= JSON.parse(output_string.asuransibayar||"[]");
				uDatatabelArr[vArridx].perawat_arr	= JSON.parse(output_string.dataperawat||"[]");

				uDatatabelArr[vArridx].init_detil 	= true;
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedis - apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			uDatatabelArr[vArridx].is_loading = false;
			console.log("(Rekammedis - apiLoaddetil) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadmaster = () => {
		uDlgviewFSObj.is_loading = true;
		setEfekview(!uEfekview);				

		/*//--TESTING_FRONTEND--/
		console.log("(Rekammedis - apiLoadmaster) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_cabangid : uInpcabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_master";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDlgviewFSObj.is_loading = false;
			if(output_string.tampil) {
				setDatadokterArr(JSON.parse(output_string.datadokter||"[]"))
				setDataanamanseArr(JSON.parse(output_string.dataanamnase||"[]"))
				setDatadiagnosaArr(JSON.parse(output_string.datadiagnosa||"[]"))
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedis - apiLoadmaster) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			uDlgviewFSObj.is_loading = false;
			console.log("(Rekammedis - apiLoadmaster) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseseditRM = async(_IDITEM) => {
		_IDITEM	= parseInt(_IDITEM)||0;if(_IDITEM <= 0) return;

		let vItemidx		= -1;
		const vArridx 		= UFunc.getObjectIndeks(uDatatabelArr,uDlgviewFSObj.rmid);
		if(uDlglookupObj.jenislookup === "DOKTER") {
			vItemidx 	= UFunc.getObjectIndeks(uDatadokterArr,_IDITEM)
			if(parseInt(uDatatabelArr[vArridx].dokter_id) === _IDITEM) {
				setDlglookupshow(false); return;
			}
		} else if(uDlglookupObj.jenislookup === "ANAMNESA") {
			vItemidx 	= UFunc.getObjectIndeks(uDataanamnaseArr,_IDITEM)
			if((uDatatabelArr[vArridx].anamnesa||"").toUpperCase() === 
				(uDataanamnaseArr[vItemidx].nama_anamnase).toUpperCase()) {
				setDlglookupshow(false); return;
			}
		} else if(uDlglookupObj.jenislookup === "DIAGNOSA") {
			//uDlgviewFSObj.lookupid = _IDITEM;
			apiProseseditRMitem(_IDITEM);
			return;
		}else {
			return;
		}
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_jenislookup : uDlglookupObj.jenislookup,
			send_rekammedisid : uDlgviewFSObj.rmid,
			send_newlookupid : _IDITEM,
			send_cabangid : uInpcabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_editrm";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.sukses) {
				if(uDlglookupObj.jenislookup === "DOKTER") {
					uDatatabelArr[vArridx].dokter_id		= _IDITEM
					uDatatabelArr[vArridx].dokter_caption	= (uDatadokterArr[vItemidx].dokter_caption||"")
					uDlgviewFSObj.dokter_caption	= (uDatadokterArr[vItemidx].dokter_caption||"");
				} else if(uDlglookupObj.jenislookup === "ANAMNESA") {
					uDatatabelArr[vArridx].anamnesa	= (uDataanamnaseArr[vItemidx].nama_anamnase||"")
					uDlgviewFSObj.anamnesa 			= (uDataanamnaseArr[vItemidx].nama_anamnase||"");
				}
				setDlglookupshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiProsesdokter) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiProsesdokter) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_rekammedisid : _IDTABEL,
			send_cabangid : uInpcabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_hapus";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.sukses) {
				uDatatabelArr.splice(vArridx,1);
				setJmlData(uJmlData-1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedis - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Rekammedis - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiCheckingperiksa = async() => {
		setActionprocess(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(async()=>{
			clearTimeout(vTimeout);
			setActionprocess(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_checking";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then(async(output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.tampil) {
				if(output_string.init_start) {
					setActionprocess(false);
					if(await( Konfirm("<b>Anda Akan memulai Billing Pemeriksaan..</b>"+
						"<br/>Apakah Anda Yakin ?"+
						"") )) {
						setActionprocess(false);
						apiProsesperiksa();
						return
					} //else setSessionaktif(true);
				} else if(output_string.init_continue) {
					uNavigate("/subpasien/pasien/periksa/"+uPasienID); return
				} else if(output_string.init_stillprocess) {
					setDatapasienObj(JSON.parse(output_string.datapasien||JSON.stringify(uDatapasienObj)))
					showToast("<b>Maaf, Pasian Sedang Dalam Proses</b>.."+
						"<br/><br/>Billing Pemeriksaan PASIEN tersebut sedang dilakukan oleh PENGGUNA LAIN."+
						"<br/>Silahkan Tunggu Sejenak, Atau Hubungi PENGELOLA untuk MEMBATALKANNYA !"+
						"")
					setActionprocess(false);
				}
			} else {
				setActionprocess(false);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Rekammedis - apiCheckingperiksa) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setActionprocess(false);
			console.log("(Rekammedis - apiCheckingperiksa) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesperiksa = () => {
		setActionprocess(true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			uNavigate("/subpasien/pasien/periksa/"+uPasienID)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_periksa";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				uNavigate("/subpasien/pasien/periksa/"+uPasienID); return;
			} else if(output_string.expired) {
				prosesExpired(); return
			} else {
				setActionprocess(false)
				setLoading(false);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Rekammedis - apiProsesperiksa) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setActionprocess(false)
			setLoading(false);
			console.log("(Rekammedis - apiProsesperiksa) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesbatal = () => {
		setActionprocess(true)

		/*/--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			//setDatapasienObj(JSON.parse(output_string.datapasien||JSON.stringify(uDatapasienObj)))
			setActionprocess(false)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_periksabatal";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setActionprocess(false)
			if(output_string.sukses) {
				setDatapasienObj(JSON.parse(output_string.datapasien||JSON.stringify(uDatapasienObj)))
				uDispatch({type: "set", gBillingperiksaObj: undefined});
				setEfekview(!uEfekview);
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedis - apiProsesbatal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setActionprocess(false)
			setLoading(false);
			console.log("(Rekammedis - apiProsesbatal) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseseditRMitem = (_LOOKUPID) => {
		const vLookupID = parseInt(_LOOKUPID)||0;//--HANYA_YG_PAKE_LOOKUP
		const vItemidx 	= UFunc.getObjectIndeks((uDlgviewFSObj.items_arr||[]),
			uDlgviewFSObj.toggleid,
			"id_item"
			);
		let vLookupidx	= -1;

		//showToast("uDlglookupObj.lookupid => "+uDlglookupObj.lookupid);
				
		if(uDlgviewFSObj.togglejenis === "DIAGNOSA") {
			vLookupidx = UFunc.getObjectIndeks(uDatadiagnosaArr, vLookupID);
			if((uDlgviewFSObj.items_arr||[])[vItemidx].diagnosa ===
				uDatadiagnosaArr[vLookupidx].nama_diagnosa) {
				setDlglookupshow(false);		
				return;
			}
			uDlgviewFSObj.togglevalue = uDatadiagnosaArr[vLookupidx].nama_diagnosa;
			setLoading(true)
		} else {
			//console.log("uDlgviewFSObj.togglevalue => "+uDlgviewFSObj.togglevalue);
			if(uDlgviewFSObj.togglejenis === "DIAGNOSAKET") {
				if(((uDlgviewFSObj.items_arr||[])[vItemidx].diagnosa_ket||"").trim().toUpperCase() ===
					(uDlgviewFSObj.togglevalue||"").trim().toUpperCase()) {
					initEditRMtoggleoff();
					return;
				}
			} else if(uDlgviewFSObj.togglejenis === "KETGIGI") {
				if(((uDlgviewFSObj.items_arr||[])[vItemidx].ket_gigi||"").trim().toUpperCase() ===
					(uDlgviewFSObj.togglevalue||"").trim().toUpperCase()) {
					initEditRMtoggleoff();
					return;
				}
			} else if(uDlgviewFSObj.togglejenis === "TINDAKAN") {
				if(((uDlgviewFSObj.items_arr||[])[vItemidx].tindakan||"").trim().toUpperCase() ===
					(uDlgviewFSObj.togglevalue||"").trim().toUpperCase()) {
					initEditRMtoggleoff();
					return;
				}
			}

			uDlgviewFSObj.is_processing = true;
			setEfekview(!uEfekview); 
		}

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDlgviewFSObj.is_processing = false;
			setLoading(false);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_rekammedisid : uDlgviewFSObj.rmid,
			send_cabangid : uInpcabangid,
			send_editjenis : uDlgviewFSObj.togglejenis,
			send_edititemid : uDlgviewFSObj.toggleid,//--trekammedis_item.id
			send_editvalue : uDlgviewFSObj.togglevalue,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_editrmitem";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDlgviewFSObj.is_processing = false;
			setLoading(false);
			if(output_string.sukses) {
				if(uDlgviewFSObj.togglejenis === "DIAGNOSA"){
					(uDlgviewFSObj.items_arr||[])[vItemidx].diagnosa = 
						uDlgviewFSObj.togglevalue; 

					setDlglookupshow(false);	
				} else if(uDlgviewFSObj.togglejenis === "DIAGNOSAKET") {
					(uDlgviewFSObj.items_arr||[])[vItemidx].diagnosa_ket =
						uDlgviewFSObj.togglevalue; 
				} else if(uDlgviewFSObj.togglejenis === "KETGIGI") {
					(uDlgviewFSObj.items_arr||[])[vItemidx].ket_gigi =
						uDlgviewFSObj.togglevalue; 
				} else if(uDlgviewFSObj.togglejenis === "TINDAKAN") {
					(uDlgviewFSObj.items_arr||[])[vItemidx].tindakan =
						(uDlgviewFSObj.togglevalue); 
				}

				initEditRMtoggleoff();
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedis - apiProsesbatal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			uDlgviewFSObj.is_processing = false;
			setLoading(false);
			console.log("(Rekammedis - apiProsesbatal) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadawal();

		return () => {
			setLoading(false);
			setDlgviewshow(false); setDlgpasienshow(false); setDlgviewFSshow(false); 
			setDlglookupshow(false);
			setDatapasienObj({});
			setDatatabelArr([]);
			uDlglookupObj.lookup_arr = ([]);
			setDatadokterArr([]);setDataanamanseArr([]); setDatadiagnosaArr([]);
    	}
	},[])
	React.useEffect(()=>{
		//uDatapasienObj.tgl_lahir = "2000-12-02";//--TEST--/
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);
	},[uJmlData]);
	React.useEffect(()=>{
		if(uIsTabelfistload === true) return;

		setRowselect(-1); 
		const vDataviewArr	= uDatatabelArr.filter(vItems=>parseInt(vItems.pg||1) === parseInt(uPageActive));
		if(vDataviewArr.length > 0) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}
		apiLoadtabel(uPageActive);
	},[uPageActive])
	React.useEffect(()=>{ 
		if(uInpcabangid <= 0) return;
		//setTabelfistload(true); apiLoadtabel(1);
		initResettabel(); apiLoadtabel();
	},[uInpcabangid])
	React.useEffect(()=>{ 
		if(!uISDlgviewshow){ 
			setIDselect(0); setDlgviewObj({}); return 
		} 
	},[uISDlgviewshow])
	React.useEffect(()=>{ 
		if(!uISDlgviewFSshow) 
			{ setDlgviewFSObj({}); return }

		if(uDataanamnaseArr.length <= 0) apiLoadmaster();
	},[uISDlgviewFSshow])
	React.useEffect(()=>{ 
		if(!uISDlglookupshow)
			{ setIDselect(0); return }
	},[uISDlglookupshow])
	//React.useEffect(()=>{ setEfekview(!uEfekview) },[uIsShowperiksa,uIsShowbatal])
	//---END EFFECT--/

	//console.log("(Rekammedis) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			{contentBack()}
			
			<CHeaderBrand className="me-auto d-md-block text-white">
				<small className="fw-bold">{uBahasaObj.caption_rekammedis||"Rekam Medis Pasien"}</small>
				{uTokenObj.userhak==="FREEROLE" && (
				<small><span className="classfontsmaller"> &middot; <small>({uPasienID})</small></span></small>
				)}
			</CHeaderBrand>

			<CHeaderNav className="">
				<MyHeaderProfile isWithLogout={!(uIsShowbatal && uIsShowperiksa)} {...props}/>
			</CHeaderNav>
		</CContainer>

		<div className="d-none">{uUrlbefore}</div>
		<div className="d-none">uPeriksainitObj = {JSON.stringify(uPeriksainitObj)}</div>
		<div className="d-none">uTokenObj = {JSON.stringify(uTokenObj)}</div>
		<div className="d-none">uIsShowbatal = {(uIsShowbatal)}</div>

		<CContainer fluid 
			className={"bg-white p-2 px-3 align-items-center"} 
			style={{minHeight:52}}>
			{(isMobile) ? (<>{contentHeadersubmobile()}</>) 
			: (
			<>
			{contentHeadersubmobile()}
			{contentHeadersub()}
			</>
			)}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-3 mb-3">
		<CContainer fluid className="px-0">
			<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between py-1">
				{(!uHtml500msg) ? (
				<div>
					<small>Data Rekam Medis</small>
					<small className="mx-1">&middot;</small>
					<small className="text-primary">[<b>{uJmlData}</b>]</small>
				</div>
				) : (<b>Page 500</b>)}

				<span className="text-muted classfontsmaller">
					<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentTabel()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
			</CCard>
		</CContainer>
		</div>
		</div>

		<MyDialogview
			options={{centered:uDlgviewObj.dlg_centered,
				size:uDlgviewObj.dlg_size||"lg",
				bgColor:uDlgviewObj.dlg_bgcolor,
			}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewObj.header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<MyDialogviewfullscreen
			show={uISDlgviewFSshow} 
			dialogHeader={uDlgviewFSObj.header}
			toggle={()=>setDlgviewFSshow(false)}
			renderContent={contentDlgviewFS()}
			{...props}/>

		<MyDialogpasien show={uIsDlgpasienshow}
			pasienID={uDlgPasienID}
			toggle={hdlToggleDlgpasien}
			{...props}/>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.header||"Lookup Data"}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.lookup_arr||[]} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>

		<MySessionextender active={uISExtenderactive} {...props}/>
		</>
	)//->
}	

export default Rekammedis